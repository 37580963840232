<template>     
  <div class="container">
    <div class="row">
      <div class="col-md-4 shadow-none mx-auto pt-2 rounded">
        <div class="card shadow">
          <div class="card-body text-center">
            <i
              class="fa fa-user-circle-o fa-2"
              aria-hidden="true"
            />
            <div class="signin-label mb-4">
              Sign In
            </div>
            <b-form @submit.stop.prevent="onSubmit">
              <b-form-group id="input-group-1">
                <div class="input-label font-weight-bold text-left">
                  Email
                </div>
                <b-form-input
                  id="email"
                  v-model="$v.form.email.$model"
                  size="sm"
                  name="email"
                  type="email"
                  :state="validateState('email')"
                  aria-describedby="email-live-feedback"
                />

                <b-form-invalid-feedback
                  id="email-live-feedback"
                >
                  This is a required field and must be an email.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group class="input-group-sm mb-3">
                <div class="input-label font-weight-bold text-left">
                  Password
                </div>
                <b-form-input
                  id="password"
                  v-model="$v.form.password.$model"
                  size="sm"
                  type="password"
                  name="password"
                  :state="validateState('password')"
                  aria-describedby="password-live-feedback"
                />

                <b-form-invalid-feedback id="password-live-feedback">
                  This is a required field.
                </b-form-invalid-feedback>
              </b-form-group>
              <div class="form-check text-left">
                <input
                  id="rememberMe"
                  class="form-check-input float-left"
                  type="checkbox"
                  value=""
                >
                <label
                  class="remember-me-label"
                  for="rememberMe"
                >
                  Remember Me
                </label>
                <span class="forgot-password float-right"> 
                  <a href="#">Forgot Password?</a>
                </span>
              </div>

              <div class="text-center">
                <b-button
                  pill
                  type="submit"
                  variant="success"
                  size="md"
                  class="mt-3"
                  :disabled="status.loggingIn || $v.form.$anyError"
                >
                  <i class="fa fa-sign-in" /> Log In
                </b-button>
              </div>
            </b-form>
          </div>
        </div>

        <b-alert
          show
          variant="danger"
          dismissible
          class="mt-2"
        >
          <strong>Admin Panel:</strong> <a href="https://ssiplengg.com">Click Here</a>
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { validationMixin } from "vuelidate"; 
import { required, minLength, email } from "vuelidate/lib/validators";

export default {
  name: 'Login',
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: '',
        password: '',
        submitted: false
      }
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(3)
      }
    }
  },
  computed: {
    ...mapState('auth', ['status'])
  },
  created () {
    this.logout();
  },
  methods: {
    ...mapActions('auth', ['login', 'logout']),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    // 3. Handle Submit - Call Store fundtion.
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const auth = this.form;
      this.login(auth);
    },
  },
};
</script>

<style scoped>
	.signin-label{ font-size: 1.2rem; color: #656262; }
	.remember-me-label{ font-size: 0.8rem; vertical-align: middle; color: #656262; }
	.forgot-password{ font-size: 0.8rem; color: #00b7c2; }
	.sign-up-label{ font-size: 0.8rem; color: #0f4c75; }
	.input-label{ font-size: 14px; color: #656262; }
	.fa-2{ font-size: 6rem; }
</style>
